/**
 * Host Logout Page
 * 
 * Logout the host, removing their login token and redirecting them back
 * to the host login page.
 */

import React from 'react'
import { navigate } from 'gatsby'

export default class HostLogoutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayed_form: ``,
      logged_in: true,
    }
  }

  componentDidMount() {
    localStorage.removeItem(`token`)
    this.setState({ logged_in: false, username: `` })
    navigate(`/host/login`)
  }

  render() {
    return <p>Logging out...</p>
  }
}
